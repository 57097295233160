<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <div>
    <MainTopSearch class="seachResults-main-top inner-container" />
    <div>
      <v-container fluid class="seachResults-index-bg px-0 ">
        <Page :query="query" class="my-8" />

        <!-- -------------------------------------------------- -->

        <CloudList :query="query" />

        <!-- -------------------------------------------------- -->

        <BlogList :query="query" />

        <!-- -------------------------------------------------- -->

        <WhiteBookList :query="query" />

        <!-- -------------------------------------------------- -->
      </v-container>
    </div>
  </div>
</template>

<script lang="babel" type="text/babel">
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
import indexMixins from '@/components/page/index.js'
export default {
  mixins: [indexMixins],
  components: {
    MainTopSearch: ()=>import('@/components/main/mainTopSearch.vue'),
    CloudList: () => import('./list/cloud.vue'),
    BlogList: () => import('./list/blog.vue'),
    WhiteBookList: () => import('./list/whiteBook.vue'),
    Page: ()=>import('./list/page.vue')
  },
  data: ()=>({
    count: 0,
  }),
  computed: {
    breadcrumb() {
      return [
        {
          title: this.$t("search.result"),
          name: "search",
        },
      ];
    },
    seoTitle() {
      return this.$store.getters['base/seoTitle']
    },
    seoDescription() {
      return this.$store.getters['base/seoDescription']
    },
    seoKeywords() {
      return this.$store.getters['base/seoKeywords']
    },
    seoCanonicalTag() {
      return this.$store.getters['base/seoCanonicalTag']
    },
    seoPhoto() {
      return this.$store.getters['base/seoPhoto']
    },
    query() {
      return this.$route.query.q
    },
  },
  methods: {
    afterIndexFetch() {
      this.count += 1
      if(this.count == 4) {
        this.setupMeta()
      }
    },
  },
};

</script>

<style lang="sass" type="text/sass">
.seachResults-index-bg
  background-image: url('~@/assets/img/bg/searchResultBg.png')
  background-position: center top
  background-size: cover
.seachResults-inner-bg
  background: #f7f7f78a
.searchEmpty
  min-height: 300px
  line-height: 300px
  font-size: 1.2rem
  color: #123456
</style>